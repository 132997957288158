import React, { useState } from "react";
import { ActionButton, Surface } from '@able/react';
import covidHeaderClose from "../../images/covid-header-close.svg";
import ElectricityIcon from "../../images/electricity.svg";
import GasIcon from "../../images/gas.svg";
import { isMobileDevice, isIpadDevice, handleSignInSignOut } from "../../utils";

const Icons = {
  'ELECTRICITYICON' : ElectricityIcon,
  'GASICON' : GasIcon,
}

export default function OrderDetailsHeader(props) {
  const {orderDescription, orderDateLabel, orderLabelForQueued, orderDate, orderDateForQueued, orderStatus, orderNumber, changeDateLabel, changeDateURL, additionalDetailsPresent, additionDetails = []} = props.header;
  const headerIcon = props.headerIcon;
  const isDesktop = !(isMobileDevice() || isIpadDevice());
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const loggedIn = props.loggedIn;
  return (
    <React.Fragment>
      <div className="order-details-header" id="order-details-header">
        { orderDescription && 
          (
            <div className="order-details-header__title responsive-width">
              {headerIcon && <img src={Icons[headerIcon]} className="energy-header-icon" />}
              <h1 role="heading" tabIndex="0">{orderDescription}</h1>
            </div>
          )
        }
        <div className="order-details-header__summary responsive-width">
          <div className="order-details-header__left">
            <div className="order-details-header__row" tabIndex="0"><span>Order status</span> <span>{orderStatus}</span></div>
            <div className="order-details-header__row" tabIndex="0"><span>Order number</span> <span>{orderNumber}</span></div>
            <div className="order-details-header__row" tabIndex="0">
              <span>{orderDateLabel}</span> 
              <div className="order-details-header__date">
                <span> {orderDate} </span>
                {changeDateLabel && <a href={changeDateURL} alt={changeDateLabel} target="_blank">{changeDateLabel}</a>}
              </div>
            </div>
            {orderLabelForQueued && orderDateForQueued && (
            <div className="order-details-header__row" tabIndex="0"><span>{orderLabelForQueued}</span> <span>{orderDateForQueued}</span></div>
            )}
            <div className="order-details-header__row order-details-header__addition-details-button" tabIndex="0">
              {additionalDetailsPresent && (
                <ActionButton
                  element="button"
                  variant="LowEmphasis"
                  onClick={e => {
                    // check for signed in.
                    if(!loggedIn) {
                      let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails'
                      handleSignInSignOut('', redirectUrl, false);
                      return;
                    }

                    // if signed in:
                    setShowAdditionalDetails(true);
                  }}
                  label="More detail about your order"
                  tabIndex="0"
                />
              )}

              {showAdditionalDetails && <div className="iframe-container">
                  <div className="iframe-parent">
                      <div
                        className="iframe-popup-td order-details-header__addition-details"
                      >
                        {!isDesktop && (
                          <div className="cta-section">
                            <button onClick={ (e) => setShowAdditionalDetails(false) }> Done </button>
                          </div>
                        )}
                        {additionDetails.map(detail => (
                          <React.Fragment>
                            <div className="order-details-header__addition-details-heading">
                              {detail?.heading}
                            </div>

                            <div className="order-details-header__addition-details-list">
                              {Object.entries(detail?.values || {}).map(([key, value]) => (
                                <div className="order-details-header__addition-details-listitem">
                                  <div className="order-details-header__addition-details-key">{key}</div>
                                  {(value || '').split('\n\n').map(
                                    (val) => <div>{val}</div> 
                                  )}
                                </div>
                              ))}
                            </div>
                          </React.Fragment>
                      ))}
                      </div>
                  </div>
                  {isDesktop && (
                    <div id="closeBtn" className="close-btn-td" onClick={ (e) => setShowAdditionalDetails(false) }>
                        <img className="close-appt-manager-btn" src={covidHeaderClose}
                            alt="COVID 19 MESSAGE"/>
                    </div>
                  )}
                  <div id="popupBack" className="popup-td" onClick={ (e) => setShowAdditionalDetails(false) }/>
                  </div>
              }
              <div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}